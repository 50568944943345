import { Model } from '@vuex-orm/core'

export default class ExchangeConnection extends Model {
    static entity = 'exchange-connection'
    static primaryKey = '_id'

    static fields () {
        return {
            _id: this.attr(null),
            created_at: this.attr(''),
            title: this.attr(''),
            user_id: this.attr(''),
            type: this.attr(false),
            api_key: this.attr(null),
        }
    }
}
