import { Model } from '@vuex-orm/core'
import Bot from "@/state/entities/bot";
import Coin from "@/state/entities/coin";
import moment from "moment/moment";

export default class Deal extends Model {
    static entity = 'deals'
    static primaryKey = '_id'

    static fields () {
        return {
            _id: this.attr(null),
            take_profit_type: this.attr(''),
            take_profit: this.attr(''),
            status: this.attr(''),
            start_price: this.attr(''),
            safety_order_amount: this.attr(''),
            quote_volume: this.attr(''),
            quote_asset: this.attr(''),
            profit: this.attr(''),
            pair: this.attr(''),
            first_order_amount: this.attr(''),
            created_at: this.attr(''),
            closed_at: this.attr(''),
            bot_id: this.attr(''),
            base_asset: this.attr(''),
            base_volume: this.attr(''),
            bot: this.belongsTo(Bot, 'bot_id', '_id'),
            coin: this.belongsTo(Coin, 'quote_asset', 'code'),
            market_type: this.attr(''),
            filled_safety_orders: this.attr(''),
            max_safety_orders: this.attr('')
        }
    }

    get created_date () {
        return moment(this.created_at).format('YYYY-MM-DD')
    }

    get created_time () {
        return moment(this.created_at).format('HH:mm')
    }

    get closed_date () {
        if (this.closed_at) {
            return moment(this.closed_at).format('YYYY-MM-DD')
        }
        return null;
    }

    get closed_time () {
        if (this.closed_at) {
            return moment(this.closed_at).format('HH:mm')
        }
        return null;
    }

    get pairFormatted () {
        return this.pair.replace("_", "/");
    }

    static apiConfig = {
        actions: {
            fetch (criteria) {
                let requestUrl = '/deals?order=DESC';

                if (criteria) {
                    for( let key in criteria ) {
                        let value = criteria[key];
                        if(value)
                            requestUrl += '&' + key + '=' + value;
                    }
                }
                return this.get(requestUrl, {
                    dataKey: 'data'
                })
            },
        }
    }
}
