export const state = {
    language: (localStorage.getItem('locale') || process.env.VUE_APP_I18N_LOCALE || 'en')
}

export const mutations = {
    SET_LOCALE(state, locale) {
        state.language = locale
        saveState('locale', state.language)
    },
}

export const getters = {
    currentLocale(state) {
        return state.language
    }
}

export const actions = {
    set({ commit }, locale) {
        commit('SET_LOCALE', locale)
    },

}

function saveState(key, state) {
    localStorage.setItem(key, (state))
}