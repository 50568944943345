import axios from "axios";
import store from "@/state/store";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
    headers: {
        "Content-type": "application/json",
    },
});

apiClient.interceptors.request.use(
    (config) => {
        let token = JSON.parse(localStorage.getItem('auth.access_token'));
        if (token) {
            config.headers['authorization'] = `Bearer ${ token }`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use((response) => {
    return response
},  (error) => {
    if (error.response.status === 401) {
        store.dispatch('auth/logOut')
    }
    return Promise.reject(error);
})

export default apiClient;
