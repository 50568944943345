import apiClient from "./http-common";

class UserService {
  userData() {
    return apiClient.get("/user-info");
  }
  registration(payload) {
    return apiClient.post("/registration", payload);
  }
}

export default new UserService();
