import { Model } from '@vuex-orm/core'

export default class User extends Model {
    static entity = 'users'

    static fields () {
        return {
            _id: this.attr(null),
            username: this.attr(''),
            email: this.attr(''),
            created_at: this.attr(''),
            uuid: this.attr(''),
        }
    }
}
