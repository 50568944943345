<template>
 <router-view></router-view>
</template>

<script>

export default {
  name: 'Damask trading',
  components: {
  },
}
</script>
