import AuthService from "@/services/auth-service";
import UserService from "@/services/UserService";
import User from "@/state/entities/user";
import { isIosApp, isAndroidApp, platform } from "webtonative";
import { setExternalUserId } from "webtonative/OneSignal";

/** @TODO fix security issue, store jwt in memory or cookie */
export const state = {
    currentUser: JSON.parse(localStorage.getItem('auth.current_user')),
    access_token: localStorage.getItem('auth.access_token'),
}

export const mutations = {
    loginFailure(state, error) {
        console.log(error);
        state.access_token = null
        state.currentUser = null
        User.deleteAll()
        removeState('auth.access_token')
        removeState('auth.current_user')
    },
    SET_TOKEN(state, token) {
        state.access_token = token
        saveState('auth.access_token', state.access_token)
    },
    SET_CURRENT_USER(state, user) {
        state.currentUser = user

        User.insert({ data: user })
        saveState('auth.current_user', state.currentUser)

        console.log('platform', platform, 'ios', isIosApp, 'android', isAndroidApp)
        if(isIosApp || isAndroidApp) {
            try {
                setExternalUserId(user.email);
            } catch (ex) {
                window.Rollbar.error("Exception OneSignal Auth", ex);
            }
        }
    },
    CLEAN_USER_STATE(state) {
        state.access_token = null
        state.currentUser = null
        User.deleteAll()
        removeState('auth.access_token')
        removeState('auth.current_user')
    }
}

export const getters = {
    loggedIn(state) {
        return !!state.access_token && !!state.currentUser
    },
    currentUser(state) {
        return state.currentUser
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },
    login({ commit }, user) {
        return AuthService.login(user).then(
            async authResponse => {
                commit('SET_TOKEN', authResponse.data.access_token)
                const profileResponse = await UserService.userData();
                commit('SET_CURRENT_USER', profileResponse.data)
                return Promise.resolve(authResponse.data);
            },
            error => {
                commit('loginFailure', error);
                return Promise.reject(error);
            },
        );
    },
    async google({commit}, token) {
        return AuthService.authByProvider(token).then(
            async authResponse => {
                console.log(authResponse)
                commit('SET_TOKEN', token)
                const profileResponse = await UserService.userData();
                commit('SET_CURRENT_USER', profileResponse.data)
                return Promise.resolve(profileResponse.data);
            },
            error => {
                commit('loginFailure', error);
                return Promise.reject(error);
            },
        );
    },
    async googleLogin({commit}, token) {
        commit('SET_TOKEN', token)
        const profileResponse = await UserService.userData();
        commit('SET_CURRENT_USER', profileResponse.data)
        return Promise.resolve(profileResponse.data);
    },

    logOut({ commit, state }) {
        if(!(!!state.access_token && !!state.currentUser)) {
            return;
        }
        commit('CLEAN_USER_STATE', null)
        /** @TODO refactoring. can't use route. plan: firstly migrate to vue3 pattern, useRoute useStore */
        window.location.href = '/login'
    },
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
function removeState(key) {
    window.localStorage.removeItem(key)
}