import apiClient from "./http-common";

class AuthService {
  login (user) {
    return apiClient.post("/login", {email: user.email, password: user.password});
  }
  authByProvider (token) {
    return apiClient.post("/auth/google-check", {token: token});
  }
  passwordResetToken (email) {
    return apiClient.post("/password-reset-token", {email: email});
  }
  passwordReset(token, password) {
    return apiClient.post("/change-password", {token: token, password: password});
  }
  changePassword(oldPassword, newPassword) {
    return apiClient.post("/authorized-change-password", {old_password: oldPassword, new_password: newPassword});
  }
  googleCallback(query) {
    return apiClient.get('/auth/google/callback?' + query)
  }
}

export default new AuthService();
