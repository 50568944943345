import { Model } from '@vuex-orm/core'

export default class Coin extends Model {
    static entity = 'coins'
    static primaryKey = 'code'

    static fields () {
        return {
            code: this.attr(''),
            title: this.attr(''),
            image_path: this.attr(''),
        }
    }
    get fullTitle () {
        return `${this.title} (${this.code})`
    }
}
